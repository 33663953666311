<template>
    <div class="galeriePage">
        <CRow>
        <CCol sm>
          <CCard style="width: 100%">
            <CCardBody>
              <CRow>
                 <CCol>
                    <Galerie :screen_id="0"></Galerie>
                 </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        </CRow>
    </div>
  </template>
  
  <script>
 import Galerie from "@/components/Galerie.vue";
  
  export default {
    name: "GaleriePage",
    components: {
      Galerie,
    },
    
  };
  </script>
  
  <style scoped>

  </style>
  